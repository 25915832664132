import { render, staticRenderFns } from "./SingleColumnOptions.vue?vue&type=template&id=5f7d2d29&"
import script from "./SingleColumnOptions.vue?vue&type=script&lang=js&"
export * from "./SingleColumnOptions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComponentBlockOptions: require('/codebuild/output/src363143284/src/eddys/components/component/block/Options.vue').default})
