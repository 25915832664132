import { render, staticRenderFns } from "./Food.vue?vue&type=template&id=206b8d3a&"
import script from "./Food.vue?vue&type=script&lang=js&"
export * from "./Food.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComponentBlockTwoOneColumnsContent: require('/codebuild/output/src363143284/src/eddys/components/component/block/TwoOneColumnsContent.vue').default,SwiperCarrousel: require('/codebuild/output/src363143284/src/eddys/components/SwiperCarrousel.vue').default,SectionLayoutWrapper: require('/codebuild/output/src363143284/src/eddys/components/global/SectionLayoutWrapper.vue').default})
